import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap';
import { FaBicycle } from 'react-icons/fa';

import EditModal from './EditModal';
import RidesTable from './Table';
import Ride from '../../../models/Ride';
import useRideService from '../../../hooks/useRideService';

import './EditRides.scss';

const EditRides = () => {
  const [rides, setRides] = useState<Ride[]>([]);
  const [selectedRide, setSelectedRide] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const rideService = useRideService();

  // Logic
  const refreshRides = () => {
    rideService
      .getAll()
      .then((value: AxiosResponse<IRide[]>) => {
        const f = value.data.map((v, i) => new Ride(v));
        setRides(f);
      });
  }

  const updateRide = (ride: Nullable<IRide>) => {
    setErrors(rideService.validate(ride));
    setSelectedRide(ride);
  };

  const saveRide = () => {
    var errors = rideService.validate(selectedRide);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      rideService
        .update(selectedRide)
        .then((response: AxiosResponse<IRide>) => {
          setIsOpen(false);
          refreshRides();
        });
    }
  };

  const deleteRide = (ride: IRide) => {
    rideService.remove(ride).then((response: AxiosResponse<any>) => {
      refreshRides();
    })
  }

  // Effects
  useEffect(() => {
    refreshRides();
  }, []);

  useEffect(() => {
    if (selectedRide) {
      setIsOpen(true);
    }
    else {
      setIsOpen(false);
    }
  }, [selectedRide]);

  return (
    <Container>
      <Row style={{ padding: 5 }}>
        <Col>
          <h2>Edit Rides</h2>
        </Col>
        <Col>
          <Button
            className="addNewRide"
            style={{ float: 'right' }}
            color="success"
            onClick={() => {
              setSelectedRide(new Ride());
              setIsOpen(true)
            }}>
            <FaBicycle /> Add New Ride
          </Button>
        </Col>
      </Row>
      <RidesTable
        rides={rides}
        onRideSelected={setSelectedRide}
        onRideDeleted={deleteRide} />
      {isOpen &&
        <EditModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          ride={selectedRide}
          onRideUpdated={updateRide}
          onSaveRideClick={saveRide}
          errors={errors} />
      }
    </Container>
  )
}

export default EditRides