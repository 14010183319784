import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import useRideService from '../hooks/useRideService';

import './Home.scss';

const Home = () => {
  const [rides, setRides] = useState<IRide[]>([]);
  const today = new Date(new Date().setHours(0, 0, 0));
  const rideService = useRideService();

  // const fevoProps: any = {
  //   'className': 'fevo-button'
  //   // 'data-event-key': 'Downtown-Dallas-Deep-Ellum--Fair-Park-Bicycle-Tour-LFbhdF8M'
  // }

  useEffect(() => {
    // const _window: any = window;
    // _window.Fevo.init({
    //   publisherKey: '020116',
    //   env: 'prod'
    // });
    // _window.Fevo.purchase('.fevo-button');

    rideService.getAll().then((value: AxiosResponse<IRide[], any>) => {
      setRides(value.data);
    })
  }, [rideService]);

  return (
    <div>
      <section className="slider" style={{ backgroundImage: "url('/images/IMG_4226.jpg')" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <h1 style={{ color: 'white' }}>Dallas Bicycle Tours</h1>
                <p className="about">Join us for a memorable bicycle tour of Downtown Dallas, Deep Ellum &amp; Fair Park on our 10-mile tour led by locals highlighting local history, murals &amp; natural surroundings.</p>
                {/* <a href="https://www.airbnb.com/experiences/3277085" target="_blank" className="btn btn-main animated fadeInUp">BOOK A TOUR</a> */}
                {/* <button type="button" className="btn btn-main animated fadeInUp fevo-button" data-event-key="Downtown-Dallas-Deep-Ellum--Fair-Park-Bicycle-Tour-LFbhdF8M">BOOK A TOUR</button> */}
                <a href="#upcomingRides" className="btn btn-main animated fadeInUp" >BOOK A TOUR</a>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about section" style={{ paddingTop: '50px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-md-9 col-sm-12">
              <div className="block">
                {/* <div id="missionStatement">
                    <h1>
                      About Dallas Bicycle Tours
                    </h1>
                    <p> Mission Statement Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum atque eos accusantium architecto molestias, illum possimus perferendis esse, autem, placeat temporibus! Autem minima aliquam soluta maxime assumenda rerum adipisci eos.</p>
                  </div>
                  <div id="missionStatement">
                    <h2>
                      Integrate AirBNB info?
                    </h2>
                    <p>AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration AirBnB integration</p>
                  </div> */}
                <div id="whatYoullSee">
                  <h1>
                    What You'll See
                  </h1>
                  <div id="features">
                    <div className="feature">
                      <div className="feature-img">
                        <img src="/images/IMG_4284.jpg" alt="Downtown" />
                      </div>
                      <div className="feature-text">
                        Downtown Dallas
                      </div>
                    </div>
                    <div className="feature">
                      <div className="feature-img">

                        <img src="/images/IMG_8572.jpg" alt="Downtown"></img>
                      </div>
                      <div className="feature-text">
                        Deep Ellum</div>
                    </div>
                    <div className="feature">
                      <div className="feature-img">
                        <img src="/images/IMG_1898.jpg" alt="Fair Park" />
                      </div>
                      <div className="feature-text">
                        Fair Park
                      </div>
                    </div>
                  </div>
                </div>
                <p>Our tour is a 10 mile ride featuring one hill at a social pace &amp; is for beginner to intermediate cyclists with some experience riding a bike. We do have the ability to customize the route depending on your needs &amp; abilities.</p>

                <p>We can rent you one of our own bicycles on request. These will be a mountain bike or a hybrid bike (these are the best types of bikes for Dallas streets).</p>

                <p>Cycling provides us a way to social distance &amp; be outdoors. Equipment is sanitized between riders.</p>

                <p>We begin by fitting you on your rental &amp; doing a safety check. You will want to bring your own, $, sunglasses, water &amp; a small fanny-pack or mini backpack if you have items to carry.</p>

                <p>The tour starts by going through Deep Ellum to the popular Deep Ellum Blues Wall as well as other murals. We will then head to the Trigger Fingers Wall followed by Fair Park. On the way back, we will wind through Downtown Dallas &amp; stop at sites such as the AT&amp;T Discovery District, Dealey Plaza, Dallas City Hall &amp; Pioneer Plaza.</p>

                <p>We conclude at Farmer's Market &amp; have a beverage with an option to stop for beignets from Le Bon Temps along the way.</p>

                <p>Photos will be taken along the way &amp; a memory video compiled. There is a restroom on the route.</p>
                {/* Title of Ride Section / Downtown Dallas / Deep Ellum 
                    All other information section */}
                {/* <h1>What's included</h1>
                  <div id="features">
                    <div className="feature">
                      <div className="feature-img">
                        image
                      </div>
                      <div className="feature-text">
                        Rentals Upon Request
                      </div>
                    </div>
                    <div className="feature">
                      <div className="feature-img">
                        image
                      </div>
                      <div className="feature-text">
                        Enjoy the Outdoors                      </div>
                    </div>
                    <div className="feature">
                      <div className="feature-img">
                        image
                      </div>
                      <div className="feature-text">
                        Downtown, Deep Ellum &amp; Fair Park
                      </div>
                    </div>
                  </div> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banner">
        <h1><a id="upcomingRides" href="#">Upcoming Rides</a></h1>
        <h2>Click flyer to book a ride!</h2>
        {rides && rides.map((ride, i) => {
          if (ride && ride.date && ride.pageUrl && today < new Date(ride.date)) {
            return (
              <a key={i} href={ride.pageUrl} target="_blank">
                <img key={i} src={`${ride.imagePath}`} alt="Flyer" />
              </a>);
          }
          return null
        })}
      </section>
      {/* <section className="about section">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className="block">
                  <div className="section-title">
                    <h2>Highland Park Holiday Light Ride</h2>
                    <p>Meet us at Glencoe Park at 7pm on Wednesdays or Fridays for a ride through Highland Park's best Christmas lights!</p>

                    <p>This 10 - 12 mile ride will take you through the beautiful streets of Drexel, Beverly, Lakeside and more. We will see special houses such as the House of 1000 Santa's and National Lampoon's X-mas. </p>
                  </div>
                  <p></p>
                  <div className="text-center">
                    <Link to="/routes/highland-park" className="btn btn-main animated fadeInUp">LEARN MORE</Link>
                    {/* <a href="#" className="btn btn-main animated fadeInUp">LEARN MORE</a>}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="block">
                  <img src="/images/holiday_ride.png" className="img-responsive" alt="Img" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
    </div>
  )
}

export default Home