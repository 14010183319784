import React from 'react'
import { FaArrowLeft, FaBicycle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './AdminPageHeader.scss';

const AdminPageHeader = (props: any) => {
  const backTo = props.backTo;
  const backToText = props.backToText;
  const icon = props.icon;
  const title = props.title;
  return (
    <div className="header">
      <div className="back-button">
        <Link to={backTo}>
          <button type="button" className="btn btn-secondary">
            <FaArrowLeft size="0.8em" /> {backToText}
          </button>
        </Link>
      </div>
      <div>
        {icon}
        <span>{title}</span>
      </div>
    </div>
  )
}

export default AdminPageHeader