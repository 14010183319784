import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Form, Row } from 'reactstrap';
import { RidesContext } from '../../../context/RidesContext';
import Ride from '../../../models/Ride';
import DateField from './fields/DateField';
import Description from './fields/Description';
import Field from './fields/Field';
import LocationField from './fields/LocationField';
import RadioField from './fields/RadioField';
import SaveRideButton from './SaveRideButton';
import { FaCheckDouble, FaEdit } from 'react-icons/fa';
import AdminPageHeader from '../AdminPageHeader';
import ImageField from './fields/ImageField';
import './RideEdit.scss';
import ImagesDropZone from './fields/ImagesDropZone';

const RideEdit = () => {
  // Context
  const params = useParams();
  const { rides } = useContext(RidesContext);

  // State
  const [ride, setRide] = useState(new Ride());
  const [json, setJson] = useState(JSON.stringify(ride));
  const [errors, setErrors] = useState<any>({});

  // Logic
  const updateRide = (id: string, value: any, index?: number) => {
    // console.log(`setting ${id} to ${value}`);
    let newRide: IRide = new Ride(ride);
    if(index != null && index > -1) {
      newRide[id][index] = value;
    }
    else {
      newRide[id] = value;
    }
    validateField(newRide, id);
    setRide(newRide);
  }

  const validateField = (newRide: IRide, name: string) => {
    var newErrors = { ...errors };
    var rideErrors = newRide.validate();
    if (rideErrors[name]) {
      newErrors[name] = rideErrors[name];
    }
    else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  }

  const validateForm = () => {
    setErrors(ride.validate());
  }

  // EFfects
  useEffect(() => {
    if (rides && params.id) {
      var r = rides.find((r: any) => r.id === params.id);
      if (r != null) {
        setRide(r);
      }
    }
    else {
      setRide(new Ride());
    }
  }, [params.id, rides]);

  // debug
  useEffect(() => {
    setJson(JSON.stringify(ride, null, 2));
  }, [ride]);

  const fieldProps = {
    ride,
    updateRide,
    validateField,
    errors,
    labelCols: 3,
    inputCols: 9
  };

  const pageHeaderProps: any = {
    backTo: '/admin/rides',
    backToText: 'Rides',
    icon: <FaEdit />,
    title: "Edit Ride"
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <AdminPageHeader {...pageHeaderProps} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={{ size: 6, offset: 3 }}>
          <Form className="form-edit">
            <Field {...fieldProps} id="name" label="Name" />
            <Field {...fieldProps} id="active" label="Active" check />
            <Field {...fieldProps} id="shortDescription" maxLength={150} label="Short Description (150 chars max)" multiline />
            <Description {...fieldProps} id="description" label="Description" />
            <RadioField {...fieldProps} id="skill" label="Skill Level" values={[
              "Beginner",
              "Intermediate",
              "Advanced"
            ]} />
            <Row>
              <Col>
                <Field currency type="number" {...fieldProps} id="priceByob" label="BYOB Price ($)"></Field>
              </Col>
              <Col>
                <Field currency type="number" {...fieldProps} id="priceRent" label="Rent Price ($)"></Field>
              </Col>
              <Col>
                <Field currency type="number" {...fieldProps} id="priceElec" label="Electric Price ($)"></Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="priceNote">
                <span className="note">NOTE:</span>
                <span>Enter a price of $0 for a ride to remove it as an option.</span>
              </Col>
            </Row>
            <Row>
              <Col><DateField {...fieldProps} id="meetupTime" label="Meetup Time" timeOnly /></Col>
              <Col><DateField {...fieldProps} id="leaveBy" label="Leave By" timeOnly /></Col>
              <Col><DateField {...fieldProps} id="endBy" label="End By" timeOnly /></Col>
            </Row>
            <Field {...fieldProps} id="weekdays" label="Days" />
            <LocationField {...fieldProps} inputCols={9} id="meetAt" label="Meet At" />
            <Field {...fieldProps} inputCols={9} id="pageUrl" label="Airbnb Page URL" />
            <Field {...fieldProps} inputCols={9} id="keywords" label="SEO Keywords" />
            <ImageField {...fieldProps} inputCols={9} id="mainImage" label="Main Image" />
            {/* <ImagesDropZone {...fieldProps} id="images" label="Images" /> */}
            <Row className="button-row">
              <Col>
                <SaveRideButton ride={ride} setRide={setRide} validateForm={validateForm} />
              </Col>
              <Col xs={2}>
                <a className="btn btn-secondary" href={`/rides/${ride.normalizedName}`} target="_blank" rel="noreferrer">
                  <FaCheckDouble /> Preview
                </a>
              </Col>
            </Row>

          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default RideEdit