import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import FieldLayout from './FieldLayout';
import { InputType } from 'reactstrap/types/lib/Input';

export interface IField {
  label: string,
  id: string,
  type?: InputType,
  maxLength?: number,
  ride: IRide,
  updateRide: (id: string, value: any, index?: number) => void,
  validateField: (newRide: IRide, name: string) => void,
  errors: any,
  labelCols: number,
  inputCols: number,
  check?: boolean,
  [key: string]: any
}

const Field = (props: IField) => {
  const id = props.id;
  const index = props.index;
  const type: InputType = props.type != null ? props.type : (props.check ? 'checkbox' : props.multiline ? 'textarea' : 'text');
  const maxLength: Nullable<number> = props.maxLength != null ? props.maxLength : null;
  const [value, setValue] = useState<any>(index > -1 ? props.ride[id][index] : props.ride[id]);
  const [cursor, setCursor] = useState<any>(null);
  const ref = useRef(null);
  const ride = props.ride;
  const updateRide = props.updateRide;
  const errors = props.errors;

  // Logic
  const onChange =
    (e: ChangeEvent<HTMLInputElement>) => {
      updateRide(id, props.check ? e.target.checked : e.target.value, index);
      setCursor(e.target.selectionStart);
    }

  // Effects
  useEffect(() => {
    let defaultValue: string | boolean = props.check ? false : '';

    let val = index > -1 ? ride[id][index] : ride[id];

    if(val != null) {
      setValue(val);
    }
    else {
      setValue(defaultValue);
    }
  }, [ride, id, props.check, index]);

  useEffect(() => {
    const input: any = ref.current;
    if (input && (input.type === 'text' || input.type === 'textarea')) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  // Addons
  const preAddon = props.currency && (
    <InputGroupText addonType="prepend">$</InputGroupText>
  );

  return (
    <FieldLayout {...props}>
      <InputGroup>
        {preAddon}
        <Input
          innerRef={ref}
          id={id}
          name={id}
          checked={props.check && value}
          type={type}
          invalid={errors && errors[id] != null}
          onChange={onChange}
          value={value}
          maxLength={maxLength ?? undefined}/>
      </InputGroup>
    </FieldLayout>
  );
}

export default Field