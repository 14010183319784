import { Outlet } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

import './Layout.scss';
import MenuAdmin from './MenuAdmin';

const Layout = () => {
  return (
    <Container className="container-admin" fluid={true}>
      <Row>
        <Col xs={12}>
          {/* <MenuDesktop />
          <MenuMobile />           */}
          <MenuAdmin />
        </Col>
        <Col xs={10}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  )
}

export default Layout