import React from 'react'
import { FaBicycle, FaQuestion, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router';

import './MenuAdmin.scss';

const AdminButton = (props: any) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(props.to);
  }

  return (
    <div className="btn btn-primary admin-button" onClick={onClick}>
      <div className="fa">
        {props.icon}
      </div>
      <span>{props.text}</span>
    </div>
  )
};

const MenuAdmin = () => {
  return (
    <div className="menu-admin">
      <AdminButton text="Rides" icon={<FaBicycle />} to="rides" />
      <AdminButton text="FAQ" icon={<FaQuestion />} to="faq" />
      <AdminButton text="SEO" icon={<FaSearch />} to="seo" />
    </div>
  )
}

export default MenuAdmin