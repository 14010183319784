import { useContext, useEffect, useState } from 'react';
import { FaEdit, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import useLogin from '../hooks/useLogin';
import { UserContext } from '../context/UserContext';

const AdminNavItems = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useContext(UserContext);
  const { signIn, signOut } = useLogin({ onLogin: props.onLogin, onLogout: props.onLogout });
  const onLoginRoute = useLocation().pathname === '/login';
  const isUserAdmin = user && user.isAdmin;
  const showAdmin = isUserAdmin || onLoginRoute;
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(user && user.bearerToken ? true : false);
  }, [user]);

  var items: JSX.Element[] = [];
  var itemCount: number = 0;

  if (!isLoggedIn) {
    items.push((
      <DropdownItem onClick={() => signIn()} key={itemCount++}>
        <FaSignInAlt /> Login
      </DropdownItem>
    ));
  }
  else {
    items.push((
      <DropdownItem onClick={() => navigate("/admin")} key={itemCount++}>
        <FaEdit /> Admin
      </DropdownItem>
    ));
    items.push((
      <DropdownItem onClick={() => navigate("/flyers/edit")} key={itemCount++}>
        <FaEdit /> Edit Flyers
      </DropdownItem>
    ))
    items.push((
      <DropdownItem onClick={() => signOut()} key={itemCount++}>
        <FaSignOutAlt /> Logout
      </DropdownItem>
    ))
  }

  return (
    showAdmin ?
      <UncontrolledDropdown inNavbar nav>
        <DropdownToggle caret nav>
          Admin
        </DropdownToggle>
        <DropdownMenu end>
          {items}
        </DropdownMenu>
      </UncontrolledDropdown>
      : <></>
  )
}

export default AdminNavItems