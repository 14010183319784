import { useContext, useEffect } from 'react'
import { UserContext } from '../context/UserContext'
import RideService from '../services/RideService';

const useRideService = (): IRideService => {
  var user = useContext(UserContext);

  useEffect(() => {
    if(user?.bearerToken) {
      RideService.setBearerToken(user.bearerToken);
    }
  
  }, [user?.bearerToken])
  
  return RideService;
}

export default useRideService