import React from 'react'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { Row, Col } from 'reactstrap'

const SocialMedia = () => {
  return (
    <Row className="socialMedia">
      <Col xs={4}>
        <h2>Social Media</h2>
      </Col>
      <Col xs={8} className="text-center">
        <a href="https://www.instagram.com/dallasbicycletours" target="_blank" rel="noreferrer"><FaInstagram fontSize="2em" /> Instagram</a>
        <a href="https://www.facebook.com/dallasbicycletours" target="_blank" rel="noreferrer"><FaFacebook fontSize="2em" /> Facebook</a>
      </Col>
    </Row>
  )
}

export default SocialMedia