import { AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { FaPlus, FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { RidesContext } from '../../../context/RidesContext';
import useRideService from '../../../hooks/useRideService';
import Ride from '../../../models/Ride';

interface ISaveRideButtonProps {
  ride: Ride;
  setRide: Dispatch<SetStateAction<Ride>>;
  validateForm: () => void;
}

const SaveRideButton = (props: ISaveRideButtonProps) => {
  const ride = props.ride;
  const setRide = props.setRide;
  const validateForm = props.validateForm;
  const { rides, setRides } = useContext(RidesContext);
  const rideService = useRideService();
  const navigate = useNavigate();

  // Logic
  const onSave = () => {
    if (ride && ride.isValid) {
      let saveFn: (ride: IRide) => Promise<AxiosResponse<IRide>>;
      let postSave: (ride: IRide) => void;
      if (!ride.id) {
        saveFn = rideService.create;
        postSave = insertRide;
      }
      else {
        saveFn = rideService.update;
        postSave = replaceRide;
      }
      saveFn(ride).then((response: AxiosResponse<IRide, any>) => {
        var newRide = new Ride(response.data);
        setRide(newRide);
        postSave(newRide);
        alert('Saved successfully');
      });
    }
    else if (!ride.isValid) {
      validateForm();
      alert('Ride is invalid - please ensure you have entered all information');
    }
  }

  const insertRide = (ride: IRide) => {
    if (rides) {
      var newRides = [...rides];
      newRides.push(ride);
      setRides(newRides);
      navigate(`/admin/rides/edit/${ride.id}`);
    }
  }

  const replaceRide = (newRide: IRide) => {
    if (rides) {
      var newRides = [...rides];
      newRides.forEach((ride: IRide, i: number) => {
        if (ride.id === newRide.id) {
          newRides[i] = newRide;
        }
      });
      setRides(newRides);
    }
  }

  const icon = ride.isNew ? <FaPlus /> : <FaSave />;

  return (
    <Button
      type="button"
      color="primary"
      onClick={onSave}
      className="save-ride-button">
      {icon}
      <span>
        {ride && !ride.id ? "Add New Ride" : "Save Ride"}
      </span>
    </Button>
  )
}

export default SaveRideButton