const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export default class Message {
  name: string;
  email: string;
  subject: string;
  body: string;
  recaptcha: Nullable<string>;
  [key: string]: any;
  
  constructor(message?: Message) {
    this.name = message?.name ?? '';
    this.email = message?.email ?? '';
    this.subject = message?.subject ?? '';
    this.body = message?.body ?? '';
    this.recaptcha = message?.recaptcha ?? '';
  }

  validate(): any {
    var errors: any = {};
    if(!this.name || this.name.length < 1) {
      errors["name"] = "Please enter your name.";
    }
    if(!this.email || !validateEmail(this.email)) {
      errors["email"] = "Please enter a valid e-mail address.";
    }
    if(!this.subject || this.subject.length < 1) {
      errors["subject"] = "Please enter a subject.";
    }
    if(!this.body || this.body.length < 1) {
      errors["body"] = "Please enter a message.";
    }
    if(!this.recaptcha || this.recaptcha.length < 1) {
      errors["recaptcha"] = "Verify you are not a robot.";
    }
    return errors;
  }
}