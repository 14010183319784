import React, { useContext } from 'react'
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { RidesContext } from '../../../context/RidesContext';
import useRide from '../../../hooks/useRide';
import AdminPageHeader from '../AdminPageHeader';
import ImagesDropZone from './fields/ImagesDropZone';

import './RideEditImages.scss';

const RideImages = ({ images }: { images: Nullable<IImage[]> }) => {
  return (
    <div className="ride-images">
      {images?.map((image: IImage, i) => (
        <div key={i}>
          <img src={image.responsiveImages[3].url} />
        </div>

      ))}
    </div>
  )
}

const RideEditImages = () => {
  const ride = useRide();

  const pageHeaderProps: any = {
    backTo: '/admin/rides',
    backToText: 'Rides',
    icon: <FaEdit />,
    title: "Ride Images"
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <AdminPageHeader {...pageHeaderProps} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={{ size: 8, offset: 2 }}>
          <RideImages images={ride.images} />
          <h3>Upload New Images</h3>
          <hr />
          <ImagesDropZone ride={ride} property="images" />
        </Col>
      </Row>
    </Container>
  )
}

export default RideEditImages