import Message from "../components/contact/Message";
import http from "../utils/http-common";

const send = (message: Message) => {
  return http.post<Message>("/contact", message);
}
const setBearerToken = (token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const EmailService = {
  send,
  setBearerToken
};

export default EmailService;
