import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router';
import { HashRouter, Routes, useLocation, useNavigate } from 'react-router-dom';
import AdminFAQ from './components/admin/FAQ';
import AdminLayout from './components/admin/Layout';
import AdminRidesEdit from './components/admin/rides/RideEdit';
import AdminRidesEditImages from './components/admin/rides/RideEditImages';
import AdminRides from './components/admin/rides/Rides';
import Contact from './components/contact/Contact';
import Home from './components/Home';
import Layout from './components/Layout';
import { UserContext } from './context/UserContext';
import EditRides from './components/rides/edit/EditRides';
import RideView from './components/rides/RideView';
import { FevoContext } from './context/FevoContext';
import { RidesContext } from './context/RidesContext';
import './custom.css';
import Ride from './models/Ride';
import RideService from './services/RideService';
import SEOService from './services/SEOService';
import AdminSEO from './components/admin/seo/SEO';
import SEO from './models/SEO';
import { SEOContext } from './context/SEOContext';
import ReactGA from 'react-ga';

const App = () => {
  const [tokenId, setTokenId] = useState<string>('');
  const [user, setUser] = useState<Nullable<IUserContext>>(null);
  const [seo, setSeo] = useState<SEO>(new SEO());
  const [rides, setRides] = useState<Nullable<IRide[]>>([]);
  const [dataEventKey, setDataEventKey] = useState<Nullable<string>>(null);
  const location = useLocation();

  let navigate = useNavigate();

  const onLogin = (tokenId: string) => {
    setTokenId(tokenId);
  }

  const onLogout = () => {
    setTokenId('');
    setUser(null);
    navigate('/');
  }

  useEffect(() => {
    if (tokenId) {
      var _window: any = window;
      var url = `${_window.API_ROOT}api/v1/user/authenticate`;

      axios.post(url, {
        IdToken: tokenId
      }).then((value: AxiosResponse<any, IUserContext>) => {
        RideService.setBearerToken(tokenId);
        setUser({
          name: value.data.name,
          bearerToken: value.data.bearerToken,
          isAdmin: value.data.isAdmin,
          onLogoutSuccess: onLogout,
          RideService
        })
      });
    }
  }, [tokenId, navigate]);

  const refreshRides = () => {
    RideService
      .getAll(true)
      .then((response: AxiosResponse<IRide[]>) => {
        const f = response.data.map((v, i) => new Ride(v));
        setRides(f);
      });
  }

  const refreshSEO = () => {
    SEOService.get().then((response: AxiosResponse<ISEO>) => {
      setSeo(response.data);
    })
  }

  useEffect(refreshRides, []);
  useEffect(refreshSEO, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <meta name="title" content="Dallas Bicycle Tours" />
        <meta name="description" content="Take a bicycle tour of Downtown Dallas, Deep Ellum & Fair Park on our 10-mile tour led by locals sharing local history, murals & natural surroundings." />
        <meta name="keywords" content="bike,tour,bicycle,guided,dallas,deep ellum" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
      </Helmet>
      <SEOContext.Provider value={{ seo, setSeo }}>
        <UserContext.Provider value={user}>
          <RidesContext.Provider value={{ rides, setRides }}>
            <FevoContext.Provider value={{ dataEventKey, setDataEventKey }}>
              <Layout onLogin={onLogin} onLogout={onLogout}>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/login' element={<Home />} />
                  <Route path='/rides/:id' element={<RideView />} />
                  {/* <Route path='/img' element={<ImgSlideshow />} /> */}
                  <Route path='/contact' element={<Contact />} />
                  {/* {user?.isAdmin && ( */}
                  <>
                    <Route path='/flyers/edit' element={<EditRides />} />
                    <Route path="/admin" element={<AdminLayout />} />
                    <Route path="/admin/rides" element={<AdminRides />} />
                    <Route path="/admin/rides/new" element={<AdminRidesEdit />} />
                    <Route path="/admin/rides/edit/:id" element={<AdminRidesEdit />} />
                    <Route path="/admin/rides/edit/images/:id" element={<AdminRidesEditImages />} />
                    <Route path="/admin/faq" element={<AdminFAQ />} />
                    <Route path="/admin/seo" element={<AdminSEO />} />
                  </>
                  {/* )} */}
                </Routes>
              </Layout>
            </FevoContext.Provider>
          </RidesContext.Provider>
        </UserContext.Provider>
      </SEOContext.Provider>
    </>
  )
}

export default App