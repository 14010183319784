import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import { FevoContext } from '../../context/FevoContext'
import useRide from '../../hooks/useRide'

import './RideView.scss'
import RideViewCard from './RideViewCard'
import RideViewFAQ from './RideViewFAQ'
import RideViewHeader from './RideViewHeader'

const RideView = () => {
  var params = useParams();
  var ride = useRide(params["id"] ?? '');

  var fevoContext = useContext(FevoContext);

  useEffect(() => {
    fevoContext.setDataEventKey(ride.dataEventKey);

    return () => {
      fevoContext.setDataEventKey(null);
    }
  });

  return (
    <Container fluid className="ride">
      <Helmet>
        <title>Dallas Bicycle Tours - {ride.name}</title>
        <meta name="description" content={ride.shortDescription ?? ''} />
        {ride.keywords != null && ride.keywords.length > 0 &&
          <meta name="keywords" content={ride.keywords} />
        }
      </Helmet>
      <RideViewHeader ride={ride}></RideViewHeader>
      <RideViewCard ride={ride}></RideViewCard>
      <RideViewFAQ />
    </Container>
  )
}

export default RideView