import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { FaFileUpload, FaSpinner } from 'react-icons/fa';
import { Progress } from 'reactstrap';
import useImageUploader, { IImageUploaderProps } from '../../../../hooks/useImageUploader';
import { IField } from './Field';
import FieldLayout from './FieldLayout';

import './ImagesDropZone.scss';

const ImagesDropZone = ({ ride, property }: { ride: IRide, property: string }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [pctComplete, setPctComplete] = useState(0);

  const revokePreviews = () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  const onImageUploaded = (image: IImage, file: File, pctComplete: number) => {
    setPctComplete(pctComplete);
    console.log(`image uploaded, ${pctComplete}% complete`);
  };

  const onComplete = () => {
    setPctComplete(0);
    revokePreviews();
    setFiles([]);
    console.log("completed uploads");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      // const newFiles = [...files];
      // newFiles.push(acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })))
      // setFiles(newFiles);
      setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))]);
    }
  });

  const {
    uploadImages,
    uploading
  } = useImageUploader({
    ride,
    property,
    onImageUploaded,
    onComplete
  });

  useEffect(() => {
    console.log(`Pct complete: ${pctComplete}%`);
  }, [pctComplete]);

  const thumbs = files.map((file: any) => (
    <div className="thumb" key={file.name} >
      <div className="thumb-inner">
        <img src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview) }} />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data URIs to avoid memory leaks, will run on unmount
    return revokePreviews;
  }, []);

  return (
    <div className="dropzone-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside className="thumbs-container">
        {thumbs}
      </aside>
      {files?.length > 0 && (
        <button
          type="button"
          className="btn btn-primary btn-upload"
          onClick={() => {
            uploadImages(files)
          }}
          disabled={uploading}>
          {!uploading && (
            <><FaFileUpload /> Upload {files.length} Images</>
          )}
          {uploading && (
            <>
              <FaSpinner className="spinner" /> Uploading...
            </>
          )}
        </button>
      )}
      {uploading && (
        <Progress bar value={pctComplete} color="success" animated />
      )}
    </div>
  );
}

export default ImagesDropZone