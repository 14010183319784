import React, { useContext, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { Col, Row } from 'reactstrap'
import { SEOContext } from '../../../context/SEOContext'
import SEO from '../../../models/SEO'
import AdminPageHeader from '../AdminPageHeader'
import Field from './fields/Field'
import SaveSEOButton from './SaveSEOButton'

import './SEO.scss'
import TagField from './fields/TagField'

const SEOEdit = () => {
  // Context
  const { seo, setSeo } = useContext(SEOContext);
  
  // State
  const [errors, setErrors] = useState<any>({});

  // Logic
  const updateSeo = (id: string, value: any) => {
    let newSeo: ISEO = new SEO(seo);
    newSeo[id] = value;
    setSeo(newSeo);
  }

  const pageHeaderProps = {
    backTo: "/admin",
    backToText: "Admin",
    icon: <FaSearch />,
    title: "SEO"
  }

  const colProps = {
    xs: {
      size: 6,
      offset: 3
    },
    sm: {}
  }

  const fieldProps = {
    model: seo,
    updateModel: updateSeo,
    validateModel: null,
    errors,
    labelCols: 3,
    inputCols: 9
  };

  return (
    <div className="seo-admin">
      <AdminPageHeader {...pageHeaderProps} />
      <Row>
        <Col {...colProps}>
          <Field {...fieldProps} id="title" label="Default Page Title" />
          <Field {...fieldProps} id="defaultDescription" label="Default Meta Description" />
          <TagField {...fieldProps} id="defaultKeywords" label="Default Meta Keywords" />
          <Field {...fieldProps} id="contactDescription" label="Contact Meta Description" />
          <TagField {...fieldProps} id="contactKeywords" label="Contact Meta Keywords" />
          <SaveSEOButton seo={seo} setSeo={setSeo} />
        </Col>
      </Row>
    </div>
  )
}

export default SEOEdit