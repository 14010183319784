import React from 'react'

import './FAQ.scss'

const FAQ = () => {
  return (
    <div>FAQ</div>
  )
}

export default FAQ