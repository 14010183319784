import http from "../utils/http-common";

const get = () => {
  return http.get<ISEO>(`json/seo.json`);
}
const set = (seo: ISEO) => {
  return http.put<IRide>("/seo", seo);
}
const setBearerToken = (token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const SEOService: ISEOService = {
  get,
  set,
  setBearerToken
};

export default SEOService;
