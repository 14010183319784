import { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap'
import { UserContext } from '../context/UserContext'
import RidesNavItem from './RidesNavItem'

import './Header.scss'
import FevoButton from './FevoButton'
import AdminNavItems from './AdminNavItems'
import { RidesContext } from '../context/RidesContext'

const SM_WIDTH_BREAKPOINT: number = 576;

const Header = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [stapleRides, setStapleRides] = useState<IRide[]>([]);
  const { rides } = useContext(RidesContext);
  const user = useContext(UserContext);
  
  const toggle = () => {
    var innerWindowWidth: number = (window as any).innerWidth;
    if (innerWindowWidth < SM_WIDTH_BREAKPOINT) {
      setIsOpen(!isOpen);
    }
    return true;
  }

  return (
    <header>
      <div className="inner">
        <div className="branding">
          <a href="/">
            <img src="/images/logo.png" alt="Dallas Bicycle Tours" width="275px" className="img-responsive" />
          </a>
        </div>
        <Navbar color="light" expand="sm" light>
          <NavbarToggler onClick={toggle} />
          <Collapse navbar isOpen={isOpen}>
            <Nav navbar>
              <NavItem>
                <NavLink to="/" onClick={toggle}>
                  Home
                </NavLink>
              </NavItem>
              <RidesNavItem rides={rides} toggle={toggle} />
              <NavItem>
                <NavLink to="/contact" onClick={toggle}>
                  Contact
                </NavLink>
              </NavItem>
              <AdminNavItems {...props} />
              <NavItem className="nav-socials">
                Visit our Social Media Pages!
                <a href="https://www.facebook.com/dallasbicycletours" target="_blank" rel="noreferrer">
                  <img src="images/fb.png" alt="Facebook"></img>
                  Facebook
                  </a>
                <a href="https://www.instagram.com/dallasbicycletours" target="_blank" rel="noreferrer">
                <img src="images/ig.png" alt="Instagram"></img>
                  Instagram
                  </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <FevoButton header />
      </div>
    </header>
  )
}

export default Header
