import { ChangeEvent, useRef } from "react";
import { FaCamera } from "react-icons/fa";

interface IUploadFlyerProps {
  handleFile: (file: File) => void;
  [key: string]: any;
}

const UploadFlyer = ({ handleFile, errors }: IUploadFlyerProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onClick = () => {
    hiddenFileInput?.current?.click();
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileUploaded: any = event.target.files[0];
      if (fileUploaded) {
        handleFile(fileUploaded);
      }
    }
  }
  

  return (
    <div style={{ width: '100%', height: '100%', textAlign: 'center', cursor: 'pointer' }} onClick={onClick}>
      <FaCamera size="7em" color="gray" />
      <div>Upload</div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        id="flyer"
        style={{ display: 'none' }} />
        {errors?.imagePath && (
            <span className="flyerError">Please upload a flyer.</span>
          )}
    </div>
  )
}

export default UploadFlyer