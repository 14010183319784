import React, { useContext } from 'react'
import { FevoContext, IFevoContext } from '../context/FevoContext';
import './FevoButton.scss'
import Ride from '../models/Ride';

interface IFevoButtonProps {
  header?: boolean;
  page?: boolean;
  text?: string;
}

const FevoButton = (props: IFevoButtonProps) => {
  const fevoContext: IFevoContext = useContext(FevoContext);
  const text = props.text ?? 'BOOK NOW';
  const header = props.header ?? false;
  const page = props.page ?? true;

  const classNames: string[] = ['btn', 'btn-main', 'animated', 'fadeInUp', 'fevo-button'];

  if (header) {
    classNames.push('fevo-button-header');
  }
  else if (page) {
    classNames.push('fevo-button-page');
  }

  var aTag = <></>;

  if (fevoContext.dataEventKey) {
    aTag = (
      <a
        href={fevoContext.dataEventKey ?? ''}
        className={classNames.join(" ")}
        target="_blank"
      >
        {text}
      </a>
    );
  }

  return aTag;
}

export default FevoButton