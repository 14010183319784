import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      &copy; 2022, Dallas Bicycle Tours, LLC. All rights reserved.
    </footer>
  )
}

export default Footer