import React from "react";
import SEO from "../models/SEO";

interface ISEOContext {
  seo: ISEO,
  setSeo: (seo: ISEO) => void
}

export const SEOContext = React.createContext<ISEOContext>({
  seo: new SEO(),
  setSeo: (seo: ISEO) => null
});