import React from 'react'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

const AddressMap = () => {
  return (
    <div>
      <h2>Address</h2>
      <ul className="address-block">
        <li><i className="ion-ios-location-outline"></i><div><strong>Dallas Bicycle Tours</strong><br />
          920 S Harwood St<br />
          Dallas, TX<br /></div></li>
        <li><FaEnvelope /> <span className="cloaked-e-mail" data-user="sruotelcycibsallad" data-domain="moc.liamg"></span></li>
        <li><FaPhone /> (469) 443-4065</li>
      </ul>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d214651.65856546862!2d-97.32113213012974!3d32.79405788640424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e98e17bb9fbc1%3A0xbcf9f72cfca3498a!2sDallas%20Farmers%20Market!5e0!3m2!1sen!2sus!4v1621628185772!5m2!1sen!2sus"
        title="google map"
        width="400"
        height="300"
        style={{ border: 2 }}
        allowFullScreen
        loading="lazy"></iframe>
    </div>
  )
}

export default AddressMap