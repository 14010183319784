import { useContext, useEffect } from 'react'
import { UserContext } from '../context/UserContext'
import SEOService from '../services/SEOService';

const useSEOService = (): ISEOService => {
  var user = useContext(UserContext);

  useEffect(() => {
    if(user?.bearerToken) {
      SEOService.setBearerToken(user.bearerToken);
    }
  
  }, [user?.bearerToken])
  
  return SEOService;
}

export default useSEOService