import { useEffect, useRef, useState } from 'react'
import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { IField } from './Field';
import FieldLayout from './FieldLayout';

interface IDescriptionProps extends IField {
}

const Description = (props: IDescriptionProps) => {
  const id = props.id;
  const ride = props.ride;
  const [value, setValue] = useState(ride[id] || null);
  const editorRef: any = useRef(null);
  const errors = props.errors;
  const showFeedback = errors && errors[id];

  // Effects
  useEffect(() => {
    setValue(ride[id]);
  }, [ride, id]);

  return (
    <FieldLayout {...props} showFeedback={showFeedback}>
      {/* @ts-ignore*/}
      <Editor
        apiKey='g1x19xalkzglx52biu975epvn49ncvi9zv6crhfka727dnsr'
        onInit={(evt: any, editor: TinyMCEEditor) => {
          editorRef.current = editor;
        }}
        initialValue={value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        // onEditorChange={(content: any, editor: TinyMCEEditor) => {
        //   setBookmark(editor.selection.getBookmark());
        //   // console.log(`bookmark: ${JSON.stringify()}`)
        //   props.updateRide(id, content);
        // }}
        onBlur={(e: any, editor: TinyMCEEditor) => {
          props.updateRide(id, editor.getContent());
        }}
      />
    </FieldLayout>
  )
}
export default Description