import { ChangeEvent, useEffect, useState } from 'react'
import { IField } from './Field'
import FieldLayout from './FieldLayout'
import { usePlacesWidget } from "react-google-autocomplete";
import Place from '../../../../models/Place';

const GOOGLE_MAPS_API_KEY = (window as any).GOOGLE_MAPS_API_KEY;

const AUTOCOMPLETE_OPTIONS: any = {
  componentRestrictions: { country: "us" },
  types: ['establishment'],
  fields: [
    'formatted_address',
    'name',
    'place_id',
    'url'
  ]
};

const LocationField = (props: IField) => {
  const id = props.id;
  const ride = props.ride;
  const updateRide = props.updateRide;
  const errors = props.errors;
  const showFeedback = errors && errors[id];

  // State
  const [value, setValue] = useState<Nullable<Place>>(null);
  const [defaultValue, setDefaultValue] = useState<string>('');

  // Logic
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value.length === 0) {
      onPlaceSelected(null);
    }
    else {
      onPlaceSelected({ name: e.target.value });
    }
  };

  const onPlaceSelected = (place: any) => {
    setValue(new Place(place));
  }

  // Effects
  useEffect(() => {
    if(value) {
      updateRide(id, value);
    }
  }, [value]);

  useEffect(() => {
    var place: Place = ride[id];
    if (place && !place.isNull) {
      setDefaultValue(place.toString());
      props.validateField(ride, id);
    }
    else {
      setDefaultValue('');
    }
  }, [ride]);

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: AUTOCOMPLETE_OPTIONS,
    onPlaceSelected: onPlaceSelected
  });

  return (
    <FieldLayout {...props} showFeedback={showFeedback}>
      <input
        type="text"
        ref={ref}
        className="googleMapsLocation form-control"
        value={defaultValue}
        onChange={onChange} />
    </FieldLayout>
  )
}

export default LocationField