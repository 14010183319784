import { ChangeEvent, useEffect } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DatePicker from 'react-datepicker';
import EditFlyer from './EditFlyer';

import './EditModal.scss'


export interface IEditModalProps {
  ride: IRide;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onRideUpdated: (ride: IRide | null) => void;
  onSaveRideClick: () => void;
  errors: any;
}

const EditModal = (props: IEditModalProps) => {
  var ride: IRide = props.ride;
  var isOpen: boolean = props.isOpen;
  var setIsOpen: (open: boolean) => void = props.setIsOpen;
  var onRideUpdated: (ride: IRide | null) => void = props.onRideUpdated;
  var onSaveRideClick: () => void = props.onSaveRideClick;

  // const [selectedFile, setSelectedFile] = useState<File>();

  const isValid = () => {
    return true;
  }

  const closeModal = () => {
    setIsOpen(false);
    onRideUpdated(null);
  }

  useEffect(() => {
    isValid();
  }, [ride]);

  return (
    <div>
      <Modal
        // fullscreen={true}
        size="lg"
        isOpen={isOpen}
        toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {ride?.name || "Add New Ride"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col xs={3}>
                <Label for="flyer">
                  Flyer
                </Label>
                <EditFlyer {...props} />
              </Col>
              <Col xs={9}>
                <Row>
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="date">
                        Date of Ride
                      </Label>
                      <DatePicker
                        selected={ride.date}
                        onChange={(date: Date) => {
                          onRideUpdated({ ...ride, date })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={8}>
                    <FormGroup>
                      <Label for="name">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter ride name..."
                        type="text"
                        value={ride?.name || ''}
                        invalid={props.errors?.name != null}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onRideUpdated({ ...ride, name: e.target.value })
                        }}>
                      </Input>
                      <FormFeedback>
                        Plese enter a name.
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="pageUrl">
                        Airbnb Page URL
                      </Label>
                      <Input
                        id="pageUrl"
                        name="pageUrl"
                        placeholder="Enter Airbnb Page URL..."
                        type="text"
                        value={ride?.pageUrl || ''}
                        invalid={props.errors?.pageUrl != null}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onRideUpdated({ ...ride, pageUrl: e.target.value })
                        }}>
                      </Input>
                      <FormFeedback>
                        Plese enter the Airbnb Page URL.
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={8}>
                    <FormGroup>
                      <Label for="name">
                        Description
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter description..."
                        type="text"
                        value={""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onRideUpdated({ ...ride })
                        }}>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <FormGroup>
                      <Label for="name">
                        Items of Interest
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter items of interest..."
                        type="text"
                        value={""}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onRideUpdated({ ...ride })
                        }}>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row> */}
              </Col>
            </Row>
            {/* <input type="file" onChange={onFileChange} /> */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          {' '}
          <Button
            color="primary"
            onClick={() => {
              if (isValid()) {
                onSaveRideClick();
              }
            }}>
            Save Ride
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default EditModal