import { AxiosResponse } from 'axios';
import React, { ChangeEvent, useRef, useState } from 'react'
import { FaSpinner, FaTrash } from 'react-icons/fa';
import useRideService from '../../../../hooks/useRideService';
import ResponsiveImage from '../../../rides/ResponsiveImage';
import { IField } from './Field';
import FieldLayout from './FieldLayout';

import './ImageField.scss'

interface IImageField extends IField {

}

const Uploading = () => (
  <div className="imageUploading">
    <FaSpinner className="spinner" />
    <span>Uploading...</span>
  </div>
);

const ImageField = (props: IImageField) => {
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const rideService = useRideService();

  const id = props.id;
  const ride = props.ride;
  const updateRide = props.updateRide;

  const fileInput = useRef<HTMLInputElement>(null);

  const handleImage = (file: File) => {
    if (ride.id) {
      setUploading(true);
      rideService
        .uploadImage(ride.id, id, file)
        .then((value: AxiosResponse<IResponsiveImage[]>) => {
          updateRide(id, value.data);
          setUploading(false);
        })
    }
  };

  const deleteImage = () => {
    if (ride.id && ride[id]) {
      setDeleting(true);
      rideService.deleteImage(ride.id, id).then(() => {
        updateRide(id, null);
        setDeleting(false);
      })
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileUploaded: any = event.target.files[0];
      if (fileUploaded) {
        handleImage(fileUploaded);
      }
    }
  }

  if(ride.isNew) {
    return (
      <FieldLayout {...props}>
        <span className="add-first">Add the new ride first before uploading an image.</span>
      </FieldLayout>
    )
  }

  return (
    <FieldLayout {...props}>
      {uploading && <Uploading />}
      {!uploading && !ride.mainImage && (
        <input type="file" ref={fileInput} onChange={handleChange} id="mainImage" />
      )}
      {!uploading && ride.mainImage && (
        <div className="mainImagePreview">
          <ResponsiveImage images={ride.mainImage.responsiveImages} />
          <button type="button" className="btn btn-danger" onClick={deleteImage}>
            {!deleting && <FaTrash />}
            {deleting && <FaSpinner className="spinner" />}
          </button>
        </div>

      )}
    </FieldLayout>
  )
}

export default ImageField