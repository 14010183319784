import React, { useState } from 'react'
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';

import './ImageCarousel.scss'
import ResponsiveImage from './rides/ResponsiveImage';

interface IImgSlideShowProps {
  images: Nullable<IImage[]>;
}

const ImageCarousel = (props: IImgSlideShowProps) => {
  const items: IImage[] = props.images ?? [];
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item: IImage) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.ordinal}>
      <ResponsiveImage images={item.responsiveImages} />
    </CarouselItem>
  ))

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...props}>
        <CarouselIndicators
          items={items} 
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev" 
            directionText="Previous" 
            onClickHandler={previous} />
          <CarouselControl
            direction="next" 
            directionText="Next" 
            onClickHandler={next} />
    </Carousel>
  )
}

export default ImageCarousel