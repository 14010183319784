import { useContext } from 'react'
import { FaBicycle, FaImages, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { RidesContext } from '../../../context/RidesContext';
import useRideService from '../../../hooks/useRideService';
import ResponsiveImage from '../../rides/ResponsiveImage';
import AdminPageHeader from '../AdminPageHeader';

import './Rides.scss'

const RideCard = (props: any) => {
  const newRide: boolean = props.newRide;
  const ride: IRide = props.ride;
  const name = props.ride?.name ?? "Add New Ride";
  const onDelete = props.onDelete;

  const navigate = useNavigate();

  const onClick = () => {
    navigate(ride ? `/admin/rides/edit/${props.ride.id}` : '/admin/rides/new');
  }

  return (
    <div className={`ride ${newRide ? 'ride-new' : ''}`} onClick={onClick}>
      {ride && <ResponsiveImage images={ride.mainImage?.responsiveImages} />}
      {newRide && <FaPlus className="fa-plus" />}
      <span className="title">
        {name}
      </span>
      <div className="menu">
        <button type="button" className="btn btn-primary" onClick={(e: any) => {
          navigate(`/admin/rides/edit/images/${ride.id}`);
          e.stopPropagation();
        }}>
          <FaImages /> Images
        </button>
        <button type="button" className="btn btn-danger" onClick={(e: any) => {
          onDelete(props.idx);
          e.stopPropagation();
        }}>
          <FaTrash />
        </button>
      </div>
    </div>
  );
}

const Rides = () => {
  const { rides, setRides } = useContext(RidesContext);
  const rideService = useRideService();

  const onDelete = (idx: number) => {
    if (rides) {
      if (window.confirm("Are you sure you want to delete this ride?")) {
        rideService
          .remove(rides[idx])
          .then(() => {
            var newRides = [...rides];
            newRides.splice(idx, 1);
            setRides(newRides);
            alert('Deleted ride successfully');
          });
      }
    }
    return false;
  }

  const pageHeaderProps = {
    backTo: "/admin",
    backToText: "Admin",
    icon: <FaBicycle />,
    title: "Rides"
  }

  return (
    <div className="rides-admin">
      <AdminPageHeader {...pageHeaderProps} />
      <div className="rides">
        {rides?.map((ride: IRide, i: number) => (
          <RideCard key={i} idx={i} ride={ride} onDelete={onDelete} />
        ))}
        <RideCard newRide />
      </div>
    </div>
  )
}

export default Rides