import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

interface IRidesNavItemProps {
  rides: Nullable<IRide[]>,
  toggle: () => void
}

const RidesNavItem = (props: IRidesNavItemProps) => {
  const toggle = props.toggle;
  const navigate = useNavigate();
  const rides = props.rides;
  const [activeRides, setActiveRides] = useState<IRide[]>([]);

  const navigateTo = (normalizedName: string) => {
    toggle();
    navigate(`/rides/${normalizedName}`);
  };

  useEffect(() => {
    if (rides && rides.length > 0) {
      setActiveRides(rides.filter((ride: IRide) => ride.active));
    }
    else {
      setActiveRides([]);
    }
  }, [rides])


  return (
    activeRides.length > 0 ?
      <UncontrolledDropdown inNavbar nav>
        <DropdownToggle caret nav>
          Rides
        </DropdownToggle>
        <DropdownMenu end>
          {activeRides
            .filter((ride: IRide) => ride.active)
            .map((ride: IRide) => (
              <DropdownItem
                key={ride.id}
                onClick={(e: any) => {
                  e.preventDefault();
                  navigateTo(ride.normalizedName ?? '')
                  return false;
                }}
                tag="a"
                href={`/rides/${ride.normalizedName}`}>
                {ride.name}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      : <></>
  )
}

export default RidesNavItem