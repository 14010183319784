import { ChangeEvent, useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { IField } from './Field'
import FieldLayout from './FieldLayout'

import './RadioField.scss';

interface IRadioField extends IField {
  values: string[];
}

const RadioField = (props: IRadioField) => {
  const id = props.id;
  const ride = props.ride;
  const updateRide = props.updateRide;
  const values = props.values;
  const errors = props.errors;
  const [value, setValue] = useState(ride[id] || '');

  // Logic
  const onChange =
    (e: ChangeEvent<HTMLInputElement>) =>
      updateRide(id, e.target.value);

  // Effects
  useEffect(() => {
    setValue(ride[id]);
  }, [ride, id]);

  return (
    <FieldLayout {...props}>
      <div className={`radio-button-group ${errors && errors[id] ? 'is-invalid' : ''}`}>
        {values && values.map((val, i) => (
          <>
            <Input
              key={`i${i}`}
              type="radio"
              id={`${id}${i}`}
              name={id}
              value={val}
              checked={val === value}
              onChange={onChange} />
            <label htmlFor={`${id}${i}`} key={`l${i}`}>
              {val}
            </label>
          </>
        ))}
      </div>
    </FieldLayout>
  )
}

export default RadioField