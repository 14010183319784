import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { IField } from './Field'
import FieldLayout from './FieldLayout'

interface IDateField extends IField {

}

const DateField = (props: IDateField) => {
  const id = props.id;
  const ride = props.ride;
  const [value, setValue] = useState(ride[id] || null);
  const errors = props.errors;
  const showFeedback = errors && errors[id];

  // Effects
  useEffect(() => {
    setValue(ride[id]);
  }, [ride, id]);

  return (
    <FieldLayout {...props} showFeedback={showFeedback}>
      <ReactDatePicker
        selected={value}
        onChange={(date) => {
          props.updateRide(id, date)
        }}
        dateFormat={props.timeOnly ? "h:mm aa" : "MMMM yyyy"}
        showMonthYearPicker={!props.timeOnly}
        showFullMonthYearPicker={!props.timeOnly}
        showTwoColumnMonthYearPicker={!props.timeOnly}
        showTimeSelect={props.timeOnly}
        showTimeSelectOnly={props.timeOnly}
        timeIntervals={15}
        timeCaption="Time"/>
    </FieldLayout>
  )
}

export default DateField