
import React, { useState } from 'react';
import { Alert } from 'reactstrap';

function EmailSentAlert(props: any) {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => {
    setVisible(false);
    if(props.onDismiss) {
      props.onDismiss();
    }
  };

  return (
    <Alert color="success" isOpen={visible} toggle={onDismiss}>
      Your e-mail has been sent. We'll get back to you!
    </Alert>
  );
}

export default EmailSentAlert;