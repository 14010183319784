import { ChangeEvent, useEffect, useState } from 'react'
import { Input } from 'reactstrap';
import FieldLayout from './FieldLayout';

export interface IField {
  label: string,
  id: string
  model: { [key: string]: any },
  updateModel: (id: string, value: any) => void,
  validateField?: (model: { [key: string]: any }, name: string) => void,
  errors: any,
  labelCols: number,
  inputCols: number,
  check?: boolean,
  [key: string]: any
}

export default function Field(props: IField) {
  const id = props.id;
  const [value, setValue] = useState<any>(props.model[id]);
  const model = props.model;
  const updateModel = props.updateModel;
  const errors = props.errors;

  // Logic
  const onChange =
    (e: ChangeEvent<HTMLInputElement>) =>
    updateModel(id, props.check ? e.target.checked : e.target.value);

  // Effects
  useEffect(() => {
    let defaultValue: string | boolean = props.check ? false : '';

    setValue(model[id] || defaultValue);
  }, [model, id, props.check]);

  return (
    <FieldLayout {...props}>
      <Input
        id={id}
        name={id}
        checked={props.check && value}
        type={props.check ? 'checkbox' : props.multiline ? 'textarea' : 'text'}
        invalid={errors && errors[id] != null}
        onChange={onChange}
        value={value} />
    </FieldLayout>
  );
}

// export default Field