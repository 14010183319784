import { Col, Row } from 'reactstrap'
import Ride from '../../models/Ride';

import './RideViewHeader.scss'

interface IRideViewHeaderProps {
  ride: Ride;
}

const RideViewHeader = (props: IRideViewHeaderProps) => {
  const ride = props.ride;

  const responsiveProps: any = {
    xs: 12,
    sm: 6
  }

  return (
    <Row className="rvHeader">
      <Col className="title" {...responsiveProps}>
        <span className="caption">Guided Tour by Dallas Bicycle Tours</span>
        <span className="name">{ride.name}</span>
      </Col>
      <Col className="short-description" {...responsiveProps}>
        {ride.shortDescription}
      </Col>
    </Row>
  )
}

export default RideViewHeader