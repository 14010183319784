import './ResponsiveImage.scss';

interface IResponsiveImageProps {
  images?: Nullable<IResponsiveImage[]>,
  alt?: string
}

const NoImage = () => (
  <div className="noImage">
    <span>No Image</span>
  </div>
)

const ResponsiveImage = (props: IResponsiveImageProps) => {
  const images = props.images;
  const alt = props.alt ?? '';

  if (!images || images.length === 0) {
    return <NoImage />;
  }

  const src = images[0].url;
  const srcSet: string[] = [];

  images.forEach((image, i) => {
    srcSet.push(`${image.url} ${image.width}w`);
  });

  return (
    <img src={src} srcSet={srcSet.join(', ')} alt={alt} />
  )
}

export default ResponsiveImage