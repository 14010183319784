import Place from "./Place";

export default class Ride implements IRide {
  id: Nullable<string>;
  name: Nullable<string>;
  active: Nullable<boolean>;
  normalizedName: Nullable<string>;
  shortDescription: Nullable<string>;
  description: Nullable<string>;
  date: Nullable<Date>;
  weekdays: Nullable<string>;
  ioi: Nullable<string>;
  durationStart: Nullable<Date>;
  durationEnd: Nullable<Date>;
  seasonal: Nullable<boolean>;
  imagePath: Nullable<string>;
  pageUrl: Nullable<string>;
  dataEventKey: Nullable<string>;
  locations: Nullable<string[]>;
  meetAt: Nullable<Place>;
  routes: Nullable<string>;
  meetupTime: Nullable<Date>;
  leaveBy: Nullable<Date>;
  endBy: Nullable<Date>;
  distance: Nullable<number>;
  duration: Nullable<number>;
  skill: Nullable<string>;
  bringCash: Nullable<boolean>;
  whatToWearOrBring: Nullable<string>;
  photosIncluded: Nullable<boolean>;
  includesFreeBev: Nullable<boolean>;
  mainImage: Nullable<IImage>;
  images: Nullable<IImage[]>;
  keywords: Nullable<string>;
  priceByob: Nullable<number>;
  priceRent: Nullable<number>;
  priceElec: Nullable<number>;

  constructor(ride?: IRide) {
    this.id = ride?.id || null;
    this.name = ride?.name || null;
    this.active = ride?.active || false;
    this.normalizedName = ride?.normalizedName || null;
    this.shortDescription = ride?.shortDescription || null;
    this.description = ride?.description || null;
    this.date = ride?.date ? new Date(ride.date) : null;
    this.imagePath = ride?.imagePath || null;
    this.pageUrl = ride?.pageUrl || null;
    this.dataEventKey = this.pageUrl || null;
    this.ioi = ride?.ioi || null;
    this.weekdays = ride?.weekdays || null;
    this.durationStart = ride?.durationStart ? new Date(ride.durationStart) : null;
    this.durationEnd = ride?.durationEnd ? new Date(ride.durationEnd) : null;
    this.seasonal = ride?.seasonal || null;
    this.locations = ride?.locations || null;
    this.meetAt = ride?.meetAt ? new Place(ride.meetAt) : null;
    this.routes = ride?.routes || null;
    this.meetupTime = ride?.meetupTime ? new Date(ride.meetupTime) : null;
    this.leaveBy = ride?.leaveBy ? new Date(ride.leaveBy) : null;
    this.endBy = ride?.endBy ? new Date(ride.endBy) : null;
    this.distance = ride?.distance || null;
    this.duration = ride?.duration || null;
    this.skill = ride?.skill || null;
    this.bringCash = ride?.bringCash || null;
    this.whatToWearOrBring = ride?.whatToWearOrBring || null;
    this.photosIncluded = ride?.photosIncluded || null;
    this.includesFreeBev = ride?.includesFreeBev || null;
    this.mainImage = ride?.mainImage || null;
    this.images = ride?.images || null;
    this.keywords = ride?.keywords || null;
    this.priceByob = ride?.priceByob || 0;
    this.priceRent = ride?.priceRent || 0;
    this.priceElec = ride?.priceElec || 0;
  }
  [key: string]: any;

  get isNew(): boolean {
    return !this.id;
  }

  get isValid(): boolean {
    return Object.keys(this.validate()).length === 0;
  }

  get startingAt(): string {
    var prices: string[] = [];
    if(this.priceByob) {
      prices.push(`$${this.priceByob} BYOB (Bike)`);
    }
    if(this.priceRent) {
      prices.push(`$${this.priceRent} w/ Bike Rental`)
    }
    if(this.priceElec) {
      prices.push(`$${this.priceElec} w/ Electric Bike Rental`)
    }
    return prices.join('<br /> ');
  }

  validate(): any {
    var errors: any = {};
    if(!this.name || this.name.length < 1) {
      errors["name"] = "Please enter a ride name.";
    }
    if(!this.shortDescription || this.shortDescription.length < 1) {
      errors["shortDescription"] = "Please enter a short description.";
    }
    if(!this.description || this.description.length < 1) {
      errors["description"] = "Please enter a description.";
    }
    if(!this.skill || this.skill.length < 1) {
      errors["skill"] = "Please select a skill level.";
    }
    if(!this.meetupTime) {
      errors["meetupTime"] = "Please set the meetup time.";
    }
    if(!this.leaveBy) {
      errors["leaveBy"] = "Please set the leave by time.";
    }
    if(!this.endBy) {
      errors["endBy"] = "Please set the end by time.";
    }
    if(!this.meetAt || this.meetAt.isNull) {
      errors["meetAt"] = "Please select the meeting location.";
    }
    if(!this.meetAt) {
      errors["pageUrl"] = "Please input the Airbnb page URL.";
    }
    return errors;
  }
}