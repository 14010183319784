import React from 'react'
import Place from '../../models/Place';

interface IMeetupLocationProps {
  place: Nullable<Place>;
}

const MeetupLocation = (props: IMeetupLocationProps) => {
  var place: Place = props.place ?? new Place();
  return (
    <>
      {place.name}
      {place.formattedAddress && <>, {place.formattedAddress}</>}
      {place.url && <> (<a href={place.url} target="_blank" rel="noreferrer">map</a>)</>}
    </>
  )
}

export default MeetupLocation