import Place from "./Place";

export default class SEO implements ISEO {
  title: Nullable<string>;
  defaultDescription: Nullable<string>;
  defaultKeywords: Nullable<string>;
  contactDescription: Nullable<string>;
  contactKeywords: Nullable<string>;

  constructor(seo?: ISEO) {
    this.title = seo?.title || null;
    this.defaultDescription = seo?.defaultDescription || null;
    this.defaultKeywords = seo?.defaultKeywords || null;
    this.contactDescription = seo?.contactDescription || null;
    this.contactKeywords = seo?.contactKeywords || null;
  }
}