import { AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { FaPlus, FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { SEOContext } from '../../../context/SEOContext';
import useRideService from '../../../hooks/useRideService';
import useSEOService from '../../../hooks/useSeoService';
import Ride from '../../../models/Ride';
import SEO from '../../../models/SEO';

interface ISaveRideButtonProps {
  seo: SEO,
  setSeo: (seo: ISEO) => void
}

const SaveRideButton = (props: ISaveRideButtonProps) => {
  const seo = props.seo;
  const setSeo = props.setSeo;
  const seoService = useSEOService();
  const navigate = useNavigate();

  // Logic
  const onSave = () => {
    if (seo) {
      seoService.set(seo).then((response: AxiosResponse<ISEO, any>) => {
        var newSeo = new SEO(response.data);
        setSeo(newSeo);
        alert('Saved successfully');
      });
    }
  }

  return (
    <Button
      type="button"
      color="primary"
      onClick={onSave}
      className="save-seo-button">
       <FaSave />
      <span>
        Save SEO
      </span>
    </Button>
  )
}

export default SaveRideButton