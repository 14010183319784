import http from "../utils/http-common";

const getAll = (staple: boolean = false) => {
  return http.get<IRide[]>(`/json/${staple ? 'staple' : 'rides'}.json`);
}
const create = (ride: IRide) => {
  return http.post<IRide>("/rides", ride);
}
const update = (ride: IRide) => {
  return http.put<IRide>("/rides", ride);
}
const remove = (ride: IRide) => {
  return http.delete<IRide>(`/rides/${ride.id}`);
}
const uploadFlyer = (file: File) => {
  var fd = new FormData();
  fd.set("file", file);
  return http.post("/rides/flyer", fd, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
const deleteFlyer = (fileName: string) => {
  return http.delete(`/rides/flyer/${fileName}`);
}
const uploadImage = (id: string, prop: string, file: File) => {
  var fd = new FormData();
  fd.set("id", id);
  fd.set("prop", prop);
  fd.set("file", file);
  return http.post(`/rides/image`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
const deleteImage = (id: string, prop: string) => {
  return http.delete(`/rides/image/${id}/${prop}`);
}
const validate = (ride: Nullable<IRide>) => {
  var errors: any = {};

  if(ride == null) {
    return errors;
  }
  else {
    if(!ride.name || ride.name.length < 1)
    {
      errors["name"] = "Please select a name.";
    }
    if(!ride.pageUrl || ride.pageUrl.length < 1) {
      errors["pageUrl"] = "Please enter the page URL from Fevo.";
    }
    if(!ride.imagePath || ride.imagePath.length < 1) {
      errors["imagePath"] = "Please upload a flyer.";
    }
  }
  
  return errors;
}
const setBearerToken = (token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const RideService: IRideService = {
  getAll,
  create,
  update,
  remove,
  
  uploadFlyer,
  deleteFlyer,
  uploadImage,
  deleteImage,
  validate,
  setBearerToken
};

export default RideService;
