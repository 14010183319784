import React from 'react'
import { Row, Col } from 'reactstrap'

import './RideViewFAQ.scss';

interface IFAQ {
  q: string;
  a: string;
}

const faqs: IFAQ[] = [
  {
    q: "How will I receive my tickets?",
    a: "You will receive your tickets via e-mail, 48 hours before the event."
  },
  {
    q: "What if there is inclement weather?",
    a: "In case of inclement weather, you may choose to either reschedule your ride to another date, or you may receive a refund."
  },
  {
    q: "What if I have to cancel?",
    a: "If you need to cancel, you may choose to either reschedule or ask for a refund if made before 24 hours before the scheduled date and time of the ride."
  }
]

const FAQ = (props: any) => {
  const faq: IFAQ = props.faq;
  return (
    <div className="faqBlock">
      <div className="questionBlock">
        <span>Q. </span>
        <span>{faq.q}</span>
      </div>
      <div className="answerBlock">
        <span>A. </span>
        <span>{faq.a}</span>
      </div>
    </div>
  )
}

const RideViewFAQ = () => {
  return (
    <Row>
      <Col xs={12} className="rvFaq">
        <h2>Frequently Asked Questions</h2>
        <Row className="faqRow">
          <Col xs={12} className="faqCol">
            {faqs && faqs.map((value, i) => (
              <FAQ faq={value} />
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default RideViewFAQ