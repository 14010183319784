export default class Place {
  placeId: Nullable<string>;
  name: string;
  formattedAddress: Nullable<string>;
  url: Nullable<string>;
  autocompleted: boolean;

  constructor(place?: any, autocompleted: boolean = true) {
    this.placeId = place?.place_id || place?.placeId || null;
    this.name = place?.name || null;
    this.formattedAddress = place?.formatted_address || place?.formattedAddress || null;
    this.url = place?.url || null;
    this.autocompleted = autocompleted;
  }

  toString() {
    var s = this.name ?? '';
    if(this.formattedAddress) {
      s += `, ${this.formattedAddress}`;
    }
    return s;
  }

  get isNull(): boolean {
    return this.toString().length === 0;
  }
}