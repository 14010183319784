import { useEffect, useState } from 'react'
import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import { refreshTokenSetup } from '../utils/refreshToken';

const CLIENT_ID =
  '883243791485-2rj1rs33vmc17pmhmms0f1qfefqp132t.apps.googleusercontent.com';

const useLogin = ({ onLogin, onLogout }: any) => {
  const [tokenId, setTokenId] = useState<string>('');
  
  const onLoginSuccess = (res: any) => {
    console.log("login success");
    setTokenId(res.getAuthResponse().id_token);
    refreshTokenSetup(res);
  };

  const onLoginFailure = (res: any) => {
    console.log('Login failed: res:', res);
  };

  const onLogoutSuccess = () => {
    setTokenId('');
    onLogout();
  }

  useEffect(() => {
    if(tokenId && onLogin) {
      onLogin(tokenId);
    }
  }, [tokenId, onLogin]);

  const { signIn } = useGoogleLogin({
    clientId: CLIENT_ID,
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailure,
    cookiePolicy: 'single_host_origin',
    isSignedIn: true
  });

  const { signOut } = useGoogleLogout({ 
    clientId: CLIENT_ID,
    onLogoutSuccess
  });

  return { signIn, signOut };
}

export default useLogin