import { Label, FormFeedback, FormGroup } from 'reactstrap';
import { IField } from './Field';

import './FieldLayout.scss';

interface IFieldLayoutProps extends IField {
  showFeedback?: boolean;
}

const FieldLayout = (props: IFieldLayoutProps) => {
  const id = props.id;
  const errors = props.errors;
  const check = props.check ?? false;
  const showFeedback = props.showFeedback ?? false;

  const label = (
    <Label for={id} className="form-label">
      {props.label}
    </Label>
  );

  return (
    <FormGroup className={check ? 'form-check' : ''}>
      {!check && label}
      {props.children}
      {check && label}
      {errors[id] && (
        <FormFeedback className={showFeedback ? 'show-feedback' : ''}>
          {errors[id]}
        </FormFeedback>
      )}
    </FormGroup>
  )
}

export default FieldLayout