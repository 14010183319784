import { useState } from 'react'
import useRideService from './useRideService';

export interface IImageUploaderProps {
  ride: IRide,
  property: string,
  onImageUploaded?: (image: IImage, file: File, pctComplete: number) => void,
  onComplete?: () => void
}

const useImageUploader = (props: IImageUploaderProps) => {
  const [uploading, setUploading] = useState(false);
  const [pctComplete, setPctComplete] = useState(0);
  const RideService = useRideService();
  const ride = props.ride;
  const rideId = ride.id;
  const rideProperty = props.property;
  const onImageUploaded = props.onImageUploaded;
  const onComplete = props.onComplete;

  // for debugging in lieu of actual image upload
  const sleep = (delay: number) => new Promise(resolve => setTimeout(resolve, delay));

  const uploadImages = async (files: File[]) => {
    if (!files || files.length == 0) {
      console.warn("files null or empty; nothing to upload");
      return;
    }
    if (!rideId || !rideProperty) {
      console.error(`Invalid rideId or rideProperty (${rideId},${rideProperty})`);
      return;
    }

    setUploading(true);

    for(var i = 0; i < files.length; ++i) {
      const file = files[i];
      try {
        //debug
        // await sleep(3000);
        // const image: any = {};
        const image = await RideService.uploadImage(rideId, rideProperty, file);
        onImageUploaded && onImageUploaded(image, file, Math.round((i + 1) / files.length * 100));
        console.log(`Successfully uploaded file ${i + 1}`);
      }
      catch (e: any) {
        throw new Error(e);
      }
    }

    setUploading(false);
    onComplete && onComplete();    
  };

  return { uploadImages, uploading, pctComplete };
}

export default useImageUploader