import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { FaSpinner, FaTrash } from 'react-icons/fa'
import { IEditModalProps } from './EditModal'
import UploadFlyer from './UploadFlyer'

import './EditFlyer.scss'
import useRideService from '../../../hooks/useRideService'

const EditFlyer = (props: IEditModalProps) => {
  const [uploading, setUploading] = useState(false);
  const rideService = useRideService();

  const handleFlyerImage = (file: File) => {
    setUploading(true);
    rideService
      .uploadFlyer(file)
      .then((value: AxiosResponse<string>) => {
        props.onRideUpdated({ ...props.ride, imagePath: value.data });
        setUploading(false);
      })
  }

  const deleteFlyerImage = () => {
    if (props.ride.imagePath) {
      var segments = props.ride.imagePath.split('/');
      rideService.deleteFlyer(segments[segments.length - 1]).then(() => {
        props.onRideUpdated({ ...props.ride, imagePath: '' })
      })
    }
  }

  if (uploading) {
    return (
      <div className="uploadingFlyer">
        <FaSpinner className="spinner" />
        <div>Uploading...</div>
      </div>
    );
  }
  else {
    if (props.ride?.imagePath && props.ride?.imagePath.length > 0) {
      return (
        <div className="flyer">
          <img src={props.ride?.imagePath} alt="" />
          <button type="button" className="btn-danger" onClick={deleteFlyerImage}>
            <FaTrash /> Delete
          </button>
        </div>
      );
    }
    else {
      return <UploadFlyer handleFile={handleFlyerImage} {...props} />;
    }
  }
}

export default EditFlyer