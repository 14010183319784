import React from 'react'
import { Col, Row } from 'reactstrap'
import Ride from '../../models/Ride'
import FevoButton from '../FevoButton'
import ImageCarousel from '../ImageCarousel'
import MeetupLocation from './MeetupLocation'
import ResponsiveImage from './ResponsiveImage'

import './RideViewCard.scss'
import SkillLevel from './SkillLevel'

interface IRideViewCardProps {
  ride: Ride
}

const getTimeText = (date: Nullable<Date>) => {
  return date?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

const DetailsBlock = (props: any) => {
  return (
    <div className="detailsBlock">
      <div className="detailsTitle">{props.title}</div>
      <div className="detailsContent">{props.children}</div>
    </div>
  )
}

const RideViewCard = (props: IRideViewCardProps) => {
  const ride = props.ride;

  const responsiveProps: any = {
    desc: {
      xs: {
        size: 12,
        order: 2
      },
      lg: {
        size: 5,
        order: 1
      }
    },
    details: {
      xs: {
        size: 12,
        order: 3
      },
      lg: {
        size: 3,
        order: 2
      }
    },
    image: {
      xs: {
        size: 12,
        order: 1
      },
      lg: {
        size: 4,
        order: 3
      }
    }
  };

  return (
    <Row className="rvCard">
      <Col {...responsiveProps.desc} className="rvCardDescription">
        <Row>
          <Col xs={12}>
            <div dangerouslySetInnerHTML={{ __html: ride.description ?? '' }} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FevoButton page />
          </Col>
          <Col xs={12} sm={6}>
            <DetailsBlock title="Skill Level">
              <SkillLevel value={ride.skill} />
            </DetailsBlock>
          </Col>
          <Col xs={12} sm={6}>
            <DetailsBlock title="Starting At">
              <div dangerouslySetInnerHTML={{ __html: ride.startingAt ?? '' }}></div>
            </DetailsBlock>
          </Col>
        </Row>
      </Col>
      <Col {...responsiveProps.details} className="rvCardDetails">
        <DetailsBlock title="Meetup Location">
          <MeetupLocation place={ride.meetAt} />
        </DetailsBlock>
        <Row>
          <Col xs={4} lg={12}>
            <DetailsBlock title="Meetup Time">
              {getTimeText(ride.meetupTime)}
            </DetailsBlock>
          </Col>
          <Col xs={4} lg={12}>
            <DetailsBlock title="Rollout Time">
              {getTimeText(ride.leaveBy)}
            </DetailsBlock>
          </Col>
          <Col xs={4} lg={12}>
            <DetailsBlock title="Return By">
              {getTimeText(ride.endBy)}
            </DetailsBlock>
          </Col>
        </Row>
      </Col>
      <Col {...responsiveProps.image} className="rvCardImage">
        {/* <ResponsiveImage images={ride.mainImage} /> */}
        <ImageCarousel images={ride.images} />
      </Col>
    </Row>
  )
}

export default RideViewCard