import React from "react";

export interface IFevoContext {
  dataEventKey: Nullable<string>,
  setDataEventKey: (dataEventKey: Nullable<string>) => void
}

export const FevoContext = React.createContext<IFevoContext>({
  dataEventKey: null,
  setDataEventKey: (dataEventKey: Nullable<string>) => null
});