import React from 'react'
import { Progress } from 'reactstrap';

import './SkillLevel.scss';

interface ISkillLevelProps {
  value: Nullable<string>;
}

const SkillLevel = (props: ISkillLevelProps) => {
  const value = props.value;
  let numValue: number = 33;
  let color: string = "success";

  switch (value) {
    case "Intermediate":
      color = "warning";
      numValue = 66;
      break;
    case "Advanced":
      color = "danger";
      numValue = 100;
      break;
  }

  return (
    <div className="skillBlock">
      <Progress value={numValue} color={color}>
        {value}
      </Progress>
    </div>
  )
}

export default SkillLevel