import { useContext } from 'react'
import { useParams } from 'react-router';
import { RidesContext } from '../context/RidesContext';
import Ride from '../models/Ride';

const GUID_PATTERN: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const useRide = (idOrNormalizedName?: string): IRide => {
  var ridesContext = useContext(RidesContext);
  const params = useParams();
  const id = idOrNormalizedName ?? params['id'];
  const isGuid = id && GUID_PATTERN.test(id);
  const isNormalizedName = id && !isGuid;

  if (!isGuid && !isNormalizedName) {
    throw new Error(`arg exception: useRide() with id = ${id} is invalid`);
  }

  if (ridesContext.rides && ridesContext.rides.length > 0) {
    let filter = null;
    if (isNormalizedName) {
      filter = (r: any) => r.normalizedName === id;
    }
    else if (isGuid) {
      filter = (r: any) => r.id === id;
    }
    if (!filter) {
      throw new Error('could not determine filter for useRide()');
    }
    var result = ridesContext.rides.filter(filter);
    if (result.length > 0) {
      return result[0];
    }
  } 

  return new Ride();
}

export default useRide