import { useEffect, useState } from 'react';
import { TagsInput } from "react-tag-input-component";
import './TagField.scss';
import FieldLayout from './FieldLayout';
import { IField } from './Field';

interface ITagField extends IField {

}

const TagField = (props: ITagField) => {
  const id = props.id;
  const [value, setValue] = useState<any>(props.model[id] ?? []);
  const model = props.model;
  const updateModel = props.updateModel;
  const errors = props.errors;

  useEffect(() => {
    // console.log(`Value change: ${JSON.stringify(value, null, 2)}`);
    updateModel(id, value);
  }, [value]);

  return (
    <FieldLayout {...props}>
      <TagsInput value={value} onChange={setValue} {...props} />
      <em>press enter or comma to add new tag</em>
    </FieldLayout>
  )
}

export default TagField