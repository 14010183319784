import { useContext } from 'react'
import { Col, Container, Row } from 'reactstrap'
import AddressMap from './AddressMap'
import ContactForm from './ContactForm'
import SocialMedia from './SocialMedia'

import { SEOContext } from '../../context/SEOContext'
import './Contact.scss'
import { Helmet } from 'react-helmet'

const Contact = () => {
  const { seo } = useContext(SEOContext);
  
  return (
    <div>
      <Helmet>
        {seo?.contactDescription && seo.contactDescription.length > 0 &&
          <meta name="description" content={seo.contactDescription} />
        }
        {seo?.contactKeywords && seo.contactKeywords.length > 0 &&
          <meta name="keywords" content={seo.contactKeywords} />
        }
      </Helmet>
      <section className="page-title bg-2" style={{ backgroundImage: 'url("/images/IMG_4015.jpg")', backgroundPosition: '50% 37%', backgroundRepeat: 'no-repeat' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <h1 style={{ fontWeight: 'bold', WebkitTextStroke: '1px black' }}>Drop Us A Note</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-form">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <h2>Contact Us</h2>
              <ContactForm />
              <SocialMedia />
            </Col>
            <Col md={12} lg={6}>
              <Row>
                <Col xs={12}>
                  <AddressMap />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Contact
