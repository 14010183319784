import { AxiosResponse } from 'axios';
import React, { ChangeEvent, createRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { FaEnvelope } from 'react-icons/fa';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import EmailService from '../../services/EmailService';
import EmailSentAlert from './EmailSentAlert';
import Message from './Message';

var siteKey = (window as any).RECAPTCHA_SITE_KEY;

const recaptchaRef = createRef<ReCAPTCHA>();

const ContactForm = () => {
  const [message, setMessage] = useState<Message>(new Message());
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  
  const validateField = (newMessage: Message, name: string) => {
    var newErrors = { ...errors };
    var msgErrors = newMessage.validate();
    if (msgErrors[name]) {
      newErrors[name] = msgErrors[name];
    }
    else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    var name = e.target.name;
    var newMessage = new Message(message);
    newMessage[name] = e.target.value;
    validateField(newMessage, name);
    setMessage(newMessage);
  }

  const sendMessage = () => {
    var msgErrors = message.validate();
    if (Object.keys(msgErrors).length === 0) {
      EmailService
        .send(message)
        .then((value: AxiosResponse<any, any>) => {
          setMessageSent(true);
          setMessage(new Message());
          recaptchaRef.current?.reset();
        });
    }
    else {
      setErrors(msgErrors);
    }
  };

  const dismissAlert = () => {
    setMessageSent(false);
  }

  return (
    <Form>
      <FormGroup>
        <Input
          id="name"
          name="name"
          placeholder="Your name"
          type="text"
          value={message.name}
          invalid={errors && errors.name != null}
          onChange={onChangeHandler}
        />
        <FormFeedback>
          Please enter your name.
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          id="email"
          name="email"
          placeholder="Your e-mail address"
          type="text"
          value={message.email}
          invalid={errors && errors.email != null}
          onChange={onChangeHandler}
        />
        <FormFeedback>
          Please enter a valid e-mail address.
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          id="subject"
          name="subject"
          placeholder="Subject"
          type="text"
          value={message.subject}
          invalid={errors && errors.subject != null}
          onChange={onChangeHandler}
        />
        <FormFeedback>
          Please enter a subject.
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          id="body"
          name="body"
          placeholder="Message"
          type="textarea"
          value={message.body}
          invalid={errors && errors.body != null}
          multiple={true}
          onChange={onChangeHandler}
        />
        <FormFeedback>
          Please enter a message.
        </FormFeedback>
      </FormGroup>
      <Row>
        <Col xs={6}>
          <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={siteKey}
              onChange={(value: string | null) => {
                var newMessage = new Message(message);
                newMessage.recaptcha = value;
                setMessage(newMessage);
                validateField(newMessage, "recaptcha");
              }} />
            {errors?.recaptcha && <span>Verify you are not a robot.</span>}
          </div>
        </Col>
        <Col xs={6} className="sendButtonContainer">
          <Button type="button" color="primary" onClick={sendMessage} className="sendMessage">
            <FaEnvelope /> Send message
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {messageSent && <EmailSentAlert onDismiss={dismissAlert} />}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm