import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SEOContext } from '../context/SEOContext'
import Footer from './Footer'
import Header from './Header'

import './Layout.scss'

const Layout = (props: any) => {
  const { seo } = useContext(SEOContext);
  return (
    <>
      <Helmet>
        {seo?.title && seo.title.length > 0 && 
          <title>{seo.title}</title>
        }
        {seo?.defaultDescription && seo.defaultDescription.length > 0 &&
          <meta name="description" content={seo.defaultDescription} />
        }
        {seo?.defaultKeywords && seo.defaultKeywords.length > 0 &&
          <meta name="keywords" content={seo.defaultKeywords} />
        }
      </Helmet>
      <Header {...props} />
      <main role="main">
        {props.children}
      </main>
      <Footer {...props} />
    </>
  )
}

export default Layout
