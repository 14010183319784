import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css';

import './Table.scss';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Ride from '../../../models/Ride';

interface IRidesTableProps {
  rides: Ride[];
  onRideSelected: (ride: IRide) => void;
  onRideDeleted: (ride: IRide) => void;
}

export interface IRideRowProps {
  index: number,
  ride: IRide,
  onEditClick: (index: number) => void,
  onDeleteClick: (index: number) => void
}

const EditButton = (props: IRideRowProps) => {
  const buttonProps = {
    color: "primary",
    onClick: () => props.onEditClick(props.index)
  };

  return (
    <Button {...buttonProps}><FaEdit /> <span>Edit</span></Button>
  );
};

const DeleteButton = (props: IRideRowProps) => {
  const buttonProps = {
    color: "danger",
    onClick: () => props.onDeleteClick(props.index)
  };

  return (
    <Button {...buttonProps}><FaTrash /> <span>Delete</span></Button>
  );
};

const TableRowHeader = () => {
  return (
    <tr>
      <th>
        Date
      </th>
      <th>
        Name
      </th>
      <th>
        Save
      </th>
    </tr>
  )
}

const TableRow = (props: IRideRowProps) => {
  //var dateOpts = { weekday: "short", year: 'numeric', month: 'short', day: 'numeric' };
  return (
    <tr>
      <td>
        {props.ride.date?.toLocaleDateString("en-US")}
      </td>
      <td>
        {props.ride.name}
      </td>
      <td className="buttons">
        <EditButton {...props} />
        <DeleteButton {...props} />
      </td>
    </tr>
  );
};

const RidesTable = (props: IRidesTableProps) => {
  const [rides, setRides] = useState<IRide[]>(props.rides);

  const onEditClick = (index: number) => {
    props.onRideSelected(rides[index]);
  }

  const onDeleteClick = (index: number) => {
    props.onRideDeleted(rides[index]);
  }

  useEffect(() => {
    if(props.rides) {
      setRides(props.rides);
    }
  }, [props.rides]);

  return (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <TableRowHeader />
      </thead>
      <tbody>
        {rides && rides.map((v, i) => <TableRow {
          ...{
            key: i,
            index: i,
            ride: v,
            onEditClick,
            onDeleteClick
          }} />)
        }
      </tbody>
    </Table>
  )
}

export default RidesTable