import React from "react";

interface IRidesContext {
  rides: Nullable<IRide[]>,
  setRides: (rides: IRide[]) => void
}

export const RidesContext = React.createContext<IRidesContext>({
  rides: [],
  setRides: (rides: IRide[]) => null
});